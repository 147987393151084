// コメント位置はPrettier対策
class AppCertification {
  //FIXME 本番設定に書き換えてください。
  /**
   * アプリケーションの定数値です。
   */
  APPVERSION = "25.01.01";
  APP_CONFIG = {
    //FIXME MockModeの有効無効
    // HttpClientのgetを対象として、dummy.jsonを読み込むフラグです。
    MOCK_MODE: false,

    /**
     * API接続用設定（VDI外アクセス）
     */
    // XT環境(SV04）
    BASE_URL: "https://xt-next-wms.nikkon-is.com/",
  };

  /**
   * Cognitoの設定です。
   */
  //FIXME 本番設定に書き換えてください。
  //営業所用
  COGNITO = {
    USER_POOL_ID: "ap-northeast-1_zQ5xffDY1",
    APP_CLIENT_ID: "4g3lbldlutiqi4fgbbm6gv5o8p",
  };

  //荷主用
  SHIPPER_COGNITO = {
    USER_POOL_ID: "ap-northeast-1_jHhbolQzz",
    APP_CLIENT_ID: "4g3lbldlutiqi4fgbbm6gv5o8p",
  };

  /** datadog */
  DATADOG = {
    DD_APPLICATION_ID: "5d30c53c-d9b1-40d0-b276-95a8f3a80fba",
    DD_CLIENT_TOKEN: "pub6d9680d1b815306c214cd5dd10632d3f",
    DD_SERVICE: "wms-xt-pc",
    DD_ENV: "user",
    DD_SESSION: 20,
  };
  /** datadog */
}

export const appCertification = new AppCertification();
